


    .image-block {
        display: flex;
        align-items: flex-end;
        align-self: flex-end;
    }

    .top-up-amount-select {

        .uk-button-group {

        }

        button {
            border-left: 5px solid transparent;
        }

        button:nth-of-type(1) {
            border-left: 5px solid var(--global-topup-1);

            &:hover {
                background-color: var(--global-topup-1);
            }

        }

        button:nth-of-type(2) {
            border-left: 5px solid var(--global-topup-2);

            &:hover {
                background-color: var(--global-topup-2);
            }

        }

        button:nth-of-type(3) {
            border-left: 5px solid var(--global-topup-3);

            &:hover {
                background-color: var(--global-topup-3);
            }

        }

        button:nth-of-type(4) {
            border-left: 5px solid var(--global-topup-4);

            &:hover {
                background-color:  var(--global-topup-4);
            }


        }

        button:nth-of-type(5) {
            border-left: 5px solid var(--global-topup-5);

            &:hover {
                background-color: var(--global-topup-5);
            }

        }
    }


    .top-up-container  {

        .image-block {
            --ratio : var(570 / 680);

            img {

                height: calc(60vh * var(--ratio));
                width: 60vh;
            }
        }

        .top-up-content{
            padding-bottom: calc(var(--global-margin) * 2);
        }
    }


    .activation-code-input-wrapper {
        .uk-input {
            border-color: var(--global-primary-background);
        }

        &.valid {
            .uk-input {
                border-color: var(--global-success-background);
            }

        }
    }

